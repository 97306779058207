import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { HeroInner } from "../components/hero";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import Tabs from "../components/tabs/tabs";


import { Swiper, SwiperSlide } from "swiper/react";
import { /* Navigation, */ Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function UseCase({ data: { nodeUseCase } }) {
  //console.log(nodeUseCase)

  const {  relationships, showCTA } = nodeUseCase;
  const { hero, section1 } = relationships;
  const seo = getMetatags(
    nodeUseCase.metatag , nodeUseCase.relationships.OGImage?.localFile?.publicURL
  );
  const iframe = false // (nodeUseCase.iframe) ? nodeUseCase.iframe : false
  //console.log(section1);

  //console.log(bgc)
  return (
    <Layout showCta={showCTA}>
      <Seo {...seo} />
      <HeroInner
        {...hero}
        tag={''}
        bgColor={"bg-grey1"}
        classNameTitle="font-semibold"
        classNameImage={(iframe) ? "max-w-[95%]" : "max-w-[858px]"}
        noImage= {(iframe)?true:false}
        iframe={iframe}
      />
      <Section1 sections={section1} />
    </Layout>
  );
}

const Section1 = ({ sections, ...props }) => {

  const colors = [
    {bg: "bg-[#FFFAE5]", shadow: "bg-[#F8EFCA]", border: 'border-[#FFCC00]'},
    {bg: "bg-[#FCF5EE]", shadow: "bg-[#F4DCC3]", border: 'border-[#F4DCC3]'},
    {bg: "bg-[#F0F9F5]", shadow: "bg-[#C1E6D6]", border: 'border-[#BFE3D3]'},
    {bg: "bg-[#FAF5FF]", shadow: "bg-[#E5EBFA]", border: 'border-[#7BA6FF]'},
  ];

  const blcoks = sections.map((section, index) => (
    <UseCaseSection
      key={index}
      section={section}
      bg={colors[index % 4].bg}
      shadow={colors[index % 4].shadow}
      borderColor={colors[index % 4].border}
    />
  ));

  return <>{blcoks}</>;
};

const UseCaseSection = ({ section, bg, shadow, borderColor }) => {


  const boxes = section.items.map((item, index) => (
    <div className="border border-dark-blue  bg-white rounded-xl p-3 lg:p-4 items-center flex" key={index}>
    <div

      className="text-dark-blue text-base  "
      dangerouslySetInnerHTML={{
        __html: item,
      }}
    />
    </div>
  ));

  const tabs = section?.relationships?.blocks.map((item, index) => {

    return (
      <Tabs.Tab  title={item.label} key={index} className="bg-white relative">
        <div className="">
          <img
            src={item?.relationships?.image?.localFile?.publicURL}
            alt={`${item?.image?.alt}`}
            className="z-50 drop-shadow-xl"
          />
          <div className={"w-full h-full absolute top-[20px] right-[-20px] -z-10 hidden lg:block " + shadow} ></div>
          </div>
      </Tabs.Tab>
    );
  });



  return (
    <section className="py-0 lg:py-12  lg:my-4 ">

      <div className="lg: max-w-[1320px] lg:mx-auto ">

        <div className={"text-center space-y-6 pt-12 "   + bg}>
          <h3 className="font-medium text-2xl lg:text-3xl ">{section.title}</h3>
          <div
            className="text-lg lg:text-2xl max-w-[920px] mx-auto"
            dangerouslySetInnerHTML={{
              __html: section.description.processed,
            }}
          />
        </div>

        <div className="relative hidden lg:block">
          <div className="flex flex-col lg:flex-row   lg:p-16 justify-between ">

            <div className="lg:w-[400px] space-y-6 lg:space-y-8 py-8 lg:py-0 px-8 lg:px-0">{boxes}</div>

            <div className="lg:w-[635px] text-grey space-y-6 pb-6 lg:pb-0  lg:pt-0">

              <Tabs
                className=""
                classNameButtons="flex flex-row space-x-1 lg:space-x-4 flex-wrap lg:flex-nowrap"
                classNameTabs="  bg-white"
                classNameButton=" text-dark-blue text-sm bg-white rounded-tl rounded-tr-xl p-3  border-b-4 border-b border-transparent"
                classNameButtonActive={"font-bold text-dark-blue text-sm bg-white rounded-tl rounded-tr-xl p-3 border-b-4 border-b " + borderColor}
                items={tabs}
              />

            </div>
            <div className="absolute inset-x-0 top-0  -z-20 h-full ">
              <div className={"h-3/4 lg:h-1/2 " + bg}></div>
              <div className="h-1/4 lg:h-1/2 bg-white"></div>
            </div>
          </div>

        </div>

        <div className={"lg:hidden " + bg} >
          <div className="lg:w-[400px] space-y-6 lg:space-y-8 py-8 lg:py-0 px-8 lg:px-0">{boxes}</div>
          <SwiperSection tabs={tabs} items={section?.relationships?.blocks} />
        </div>

      </div>
    </section>
  );
};


const SwiperSection = ({items}) => {

  return (
    <div className="">
      <Swiper
        modules={[/* Navigation, */ Pagination]}
        //className="  lg:w-3/5 "
        spaceBetween={10}
        slidesPerView={1.1}
        //onSlideChange={() => console.log('slide change')}
        //onSwiper={(swiper) => console.log(swiper)}
        navigation={false}
        pagination={{ clickable: true }}
      >
        {items.map((item, i) => (
          <SwiperSlide className="mb-12 mt-6  " key={i}>
            <div className="">
              <img
                src={item?.relationships?.image?.localFile?.publicURL}
                alt={`${item?.image?.alt}`}
                className="z-50 drop-shadow-xl"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )

}


export default UseCase;

export const query = graphql`
  query usecase($id: String!) {
    nodeUseCase(id: { eq: $id }) {
      id
      title
      iframe: field_iframe
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        section1: field_paragraph_repeter {
          title: field_hero_title
          description: field_description_html {
            processed
          }
          items: field_list_top
          paragraph_type {
            drupal_internal__target_id
          }
          relationships {
            blocks: field_repeater {
              id
              image: field_image {
                alt
                title
              }
              label: field_label
              relationships {
                image: field_image {
                  ...Image
                }
              }
            }
          }
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
      }
    }
  }
`;
